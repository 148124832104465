import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=23e51f0c&scoped=true&"
import script from "./Details.vue?vue&type=script&lang=js&"
export * from "./Details.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e51f0c",
  null
  
)

export default component.exports